import { Product as AppProduct } from 'contracts';

interface BusinessProduct extends Record<keyof typeof AppProduct, boolean> {
  current: AppProduct;
}

class Product implements BusinessProduct {
  get current(): AppProduct {
    const { theme } = document.documentElement.dataset;

    return theme as AppProduct;
  }

  get LiveJasmin(): boolean {
    return this.current === AppProduct.LiveJasmin;
  }

  get Oranum(): boolean {
    return this.current === AppProduct.Oranum;
  }
}

export default new Product();

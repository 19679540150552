import is from './is';

/**
 * Deep compare data equality of given set.
 * @description Note: order of the elements affects the end result.
 * E.g.: [1, 2] !== [2, 1].
 * @param source
 * @param target
 * @param maxDepth
 */
const deepEqual = <T>(source: T, target: T, maxDepth = Infinity): boolean => {
  const depthLevel = maxDepth === Infinity ? Infinity : maxDepth - 1;

  if (Object.is(source, target) || depthLevel <= 0) {
    return true;
  }

  if (is.func(source)) {
    return source.toString() === (target as Function).toString();
  }

  if (is.array(source)) {
    if (source.length !== (target as Array<T>)?.length) {
      return false;
    }

    return source.every((entry, idx) => deepEqual(entry, target[idx], depthLevel));
  }

  if (is.object(source)) {
    const sourceEntries = Object.entries(source);
    const targetEntries = Object.getOwnPropertyNames(target as Record<string, unknown>);

    if (sourceEntries.length !== targetEntries.length) {
      return false;
    }

    return sourceEntries.every(([key, value]) => deepEqual(value, target[key]), depthLevel);
  }

  if (is.date(source)) {
    return source.getTime() === (target as Date).getTime();
  }

  if (is.regex(source)) {
    return source.toString() === (target as RegExp).toString();
  }

  return false;
};

export default deepEqual;

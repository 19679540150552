import is from './is';

const toKebabCase = (...values: Array<string | undefined>): string => {
  if (!values || !values.length) {
    return '';
  }

  return (
    values
      .filter((v) => !is.nullish(v))
      .join(' ')
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+\d*|\b)|[A-Z]?[a-z]+\d*|[A-Z]|\d+/g)
      ?.map((x) => x.toLowerCase())
      .join('-') ?? ''
  );
};

const toSnakeCase = (...values: Array<string | undefined>): string => {
  if (!values || !values.length) {
    return '';
  }

  return (
    values
      .filter((v) => !is.nullish(v))
      .join(' ')
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+\d*|\b)|[A-Z]?[a-z]+\d*|[A-Z]|\d+/g)
      ?.map((x) => x.toLowerCase())
      .join('_') ?? ''
  );
};

const toCamelCase = (value: string | undefined, lowerCamelCase = true): string => {
  if (!value) {
    return '';
  }

  return (
    value
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+\d*|\b)|[A-Z]?[a-z]+\d*|[A-Z]|\d+/g)
      ?.map((x, idx) => {
        if (lowerCamelCase && idx === 0) {
          return x.toLowerCase();
        }

        return x.substring(0, 1).toUpperCase() + x.substring(1);
      })
      .join('') ?? ''
  );
};

const path = (...args: Array<string | undefined>): string => {
  return args
    .filter(Boolean)
    .join('/')
    .replace(/\/{2,}/g, '/')
    .replace(/(.+)(\/$)/g, '$1');
};

const url = (...args: string[]): string => {
  const REGEXP_PROTOCOL = /^([^/:]+):\/*/;
  const REGEXP_IS_PLAIN_PROTOCOL = /^[^/:]+:\/*$/;
  const REGEXP_FILE_PROTOCOL = /^file:\/{3}/;
  const REGEXP_START_WITH_SLASH = /^\/+/;
  const REGEXP_END_WITH_SLASH = /\/+$/;
  const REGEXP_TRAILING_SLASH_BEFORE_PARAM_HASH = /\/(\?|&|#[^!])/g;

  const parts = [...args.filter(Boolean)];

  if (!parts.length) {
    return '';
  }

  if (parts[0].match(REGEXP_IS_PLAIN_PROTOCOL)) {
    // if it is a plain protocol, we concatenate with the next part
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    parts[0] = parts.shift() + parts[0];
  }

  parts[0] = parts[0].replace(REGEXP_PROTOCOL, (match, protocol: string) => {
    return match.match(REGEXP_FILE_PROTOCOL) ? `${protocol}:///` : `${protocol}://`;
  });

  return parts
    .map((part, idx, arr) => {
      let normalized = part;

      if (idx > 0) {
        normalized = normalized.replace(REGEXP_START_WITH_SLASH, '');
      }

      if (idx < arr.length - 1) {
        return normalized.replace(REGEXP_END_WITH_SLASH, '');
      }

      return normalized.replace(REGEXP_END_WITH_SLASH, '/');
    })
    .join('/')
    .replace(REGEXP_TRAILING_SLASH_BEFORE_PARAM_HASH, '$1');
};

const toTestId = (...ids: string[]): string => {
  return toKebabCase(...ids).toLowerCase();
};

export default {
  toKebabCase,
  toSnakeCase,
  toCamelCase,
  toTestId,
  path,
  url,
};

import { Observable, fromEvent, take } from 'rxjs';
import type { Subscription } from 'rxjs';

import createOperatorSubscriber from './create-operator-subscriber';

const retryWhenOnline = <T>(): ((source$: Observable<T | undefined>) => Observable<T>) => {
  return (source$) => {
    return new Observable<T>((subscriber) => {
      let innerSubscription: Subscription | null;

      const subscribeForRetry = (): void => {
        innerSubscription = source$.subscribe(
          createOperatorSubscriber(
            subscriber,
            (value) => {
              subscriber.next(value);
            },
            undefined,
            (error) => {
              if (navigator.onLine) {
                subscriber.error(error);

                return;
              }

              fromEvent(window, 'online')
                .pipe(take(1))
                // eslint-disable-next-line rxjs/no-nested-subscribe
                .subscribe(() => {
                  innerSubscription?.unsubscribe?.();
                  innerSubscription = null;

                  subscribeForRetry();
                });
            }
          )
        );
      };

      subscribeForRetry();
    });
  };
};

export default retryWhenOnline;

/**
 * CSS class name render
 * @example
 * className(true && 'foo', false && 'bar');
 * // outputs: 'foo'
 * @param {string[]|boolean[]|undefined[]|null[]} classes
 * @return {string}
 */
const className = (...classes: Array<string | boolean | undefined | null>): string => classes.filter(Boolean).join(' ');

export default className;

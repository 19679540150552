const CSSLengthUnits = [
  'cm',
  'mm',
  'Q',
  'in',
  'pc',
  'pt',
  'px',
  'em',
  'ex',
  'ch',
  'rem',
  'lh',
  'vw',
  'vh',
  'vmin',
  'vmax',
  '%',
];

type CSS = (value: string) => {
  readonly size: number;
  readonly unit: string | undefined;
  readonly hasLengthUnit: boolean;
  readonly hasValidLengthUnit: boolean;
  toString(): string;
};

const css: CSS = (value: string) => {
  const { 1: size, 2: unit } = /([\d.]+)([%a-z]*)/i.exec(value.toString()) || [undefined, undefined, undefined];

  return {
    get size() {
      return +(size ?? '');
    },
    get unit() {
      return unit;
    },
    get hasLengthUnit(): boolean {
      return CSSLengthUnits.some((nativeUnit) => nativeUnit === unit || nativeUnit === value);
    },
    get hasValidLengthUnit(): boolean {
      return CSSLengthUnits.includes(unit ?? '') || CSSLengthUnits.includes(value);
    },
    toString(): string {
      return value.toString();
    },
  };
};

export { CSSLengthUnits };
export default css;

import is from './is';
import typeOf from './type-of';

/**
 * Deep copy/clone element type
 * @description superficially clone the structure but not the elements.
 * @param {T} value
 * @return {T}
 */
const deepCopy = <T>(value: T): T => {
  let copy: unknown;

  if (is.array(value)) {
    copy = [...value.map((entry) => deepCopy(entry))];
  } else if (is.object(value)) {
    copy = { ...value };

    Object.keys(copy as object).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      (copy as object)[key] = deepCopy((copy as object)[key]);
    });
  } else if (typeOf(value) === 'date') {
    copy = new Date(value as unknown as Date);
  } else if (typeOf(value) === 'map') {
    copy = new Map(value as unknown as Map<unknown, unknown>);
  } else if (typeOf(value) === 'set') {
    copy = new Set(value as unknown as Set<unknown>);
  } else {
    copy = value;
  }

  return copy as T;
};

export default deepCopy;

import type { AriaAttributes } from 'contracts';

import is from './is';

const ariaAttributes = <T extends object>(values: T | undefined): AriaAttributes => {
  if (!is.object(values)) {
    return {} satisfies AriaAttributes;
  }

  return Object.keys(values).reduce((acc, key) => {
    if (key.startsWith('aria-')) {
      acc[key] = values[key];
    }

    return acc;
  }, {} satisfies AriaAttributes);
};

export default { ariaAttributes };

import happen from './happen';

type GridBreakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | '3xl';

interface Breakpoints {
  readonly current: GridBreakpoint;
  min: (name: GridBreakpoint) => boolean;
  max: (name: GridBreakpoint) => boolean;
  between: (lower: GridBreakpoint, upper: GridBreakpoint) => boolean;
}

const gridBreakpoints: Record<GridBreakpoint, number> = {
  '3xl': 1600,
  xxl: 1400,
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 576,
  xs: 0,
};

const breakpoints: Breakpoints = {
  get current() {
    const { innerWidth } = window;

    return Object.entries(gridBreakpoints).find(([, size]) => innerWidth >= size)![0] as GridBreakpoint;
  },
  min(name) {
    return happen(window.innerWidth).greaterThanOrEqual(gridBreakpoints[name]);
  },
  max(name) {
    return happen(window.innerWidth).lessThanOrEqual(gridBreakpoints[name]);
  },
  between(lower, upper) {
    return happen(window.innerWidth).between(gridBreakpoints[lower], gridBreakpoints[upper]);
  },
};

export default breakpoints;

import { filter, map } from 'rxjs';
import type { Observable } from 'rxjs';
import is from 'utils/is';

const filterWhileNullish = <T>(): ((source$: Observable<T | undefined>) => Observable<T>) => {
  return (source$) =>
    source$.pipe(
      filter((data) => !is.nullish(data)),
      map((data) => data as Extract<T, undefined>)
    );
};

export default filterWhileNullish;

import parse from './parse';

const COOKIE_PREFIX = '__msc';

type CookieCategories = 'essential' | 'analytical' | 'functional';

// Dots and spaces in cookie name got replaced to underscore!
// https://www.php.net/manual/en/reserved.variables.cookies.php
const cookies = {
  nameCreator(category: CookieCategories, uniqueName: string) {
    const cookieName = `${COOKIE_PREFIX}-${parse.toKebabCase(category)}-${parse.toKebabCase(uniqueName)}`;

    return cookieName;
  },
};

export default cookies;

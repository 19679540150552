import { Subscriber } from 'rxjs';

/**
 * @TODO deprecate this class in favour of "operate" method from RxJS v8 (yet to be released)
 *
 * This class is a copy from RxJS v7 which is not exported.
 * It's purpose is to prevent subscription duplication, which would lead to undesired behavior.
 * @link https://github.com/ReactiveX/rxjs/blob/7.x/src/internal/operators/OperatorSubscriber.ts
 */

/* eslint-disable no-underscore-dangle, @typescript-eslint/no-explicit-any */
class OperatorSubscriber<T> extends Subscriber<T> {
  /**
   * Creates an instance of an `OperatorSubscriber`.
   * @param destination The downstream subscriber.
   * @param onNext Handles next values, only called if this subscriber is not stopped or closed. Any
   * error that occurs in this function is caught and sent to the `error` method of this subscriber.
   * @param onError Handles errors from the subscription, any errors that occur in this handler are caught
   * and send to the `destination` error handler.
   * @param onComplete Handles completion notification from the subscription. Any errors that occur in
   * this handler are sent to the `destination` error handler.
   * @param onFinalize Additional finalization logic here. This will only be called on finalization if the
   * subscriber itself is not already closed. This is called after all other finalization logic is executed.
   * @param shouldUnsubscribe An optional check to see if an unsubscribe call should truly unsubscribe.
   * NOTE: This currently **ONLY** exists to support the strange behavior of {@link groupBy}, where unsubscription
   * to the resulting observable does not actually disconnect from the source if there are active subscriptions
   * to any grouped observable. (DO NOT EXPOSE OR USE EXTERNALLY!!!)
   */
  constructor(
    destination: Subscriber<any>,
    onNext?: (value: T) => void,
    onComplete?: () => void,
    onError?: (err: any) => void,
    private onFinalize?: () => void,
    private shouldUnsubscribe?: () => boolean
  ) {
    super(destination);

    this._next = onNext
      ? function handleOnNext(this: OperatorSubscriber<T>, value: T) {
          try {
            onNext(value);
          } catch (err) {
            destination.error(err);
          }
        }
      : super._next;

    this._error = onError
      ? function handleOnError(this: OperatorSubscriber<T>, err: any) {
          try {
            onError(err);
          } catch (error) {
            // Send any errors that occur down stream.
            destination.error(error);
          } finally {
            // Ensure finalization.
            this.unsubscribe();
          }
        }
      : super._error;

    this._complete = onComplete
      ? function handleOnComplete(this: OperatorSubscriber<T>) {
          try {
            onComplete();
          } catch (err) {
            // Send any errors that occur down stream.
            destination.error(err);
          } finally {
            // Ensure finalization.
            this.unsubscribe();
          }
        }
      : super._complete;
  }

  unsubscribe(): void {
    if (!this.shouldUnsubscribe || this.shouldUnsubscribe()) {
      const { closed } = this;

      super.unsubscribe();

      // Execute additional teardown if we have any and we didn't already do so.
      if (!closed) {
        this.onFinalize?.();
      }
    }
  }
}
/* eslint-enable no-underscore-dangle */

function createOperatorSubscriber<T>(
  destination: Subscriber<any>,
  onNext?: (value: T) => void,
  onComplete?: () => void,
  onError?: (err: any) => void,
  onFinalize?: () => void
): Subscriber<T> {
  return new OperatorSubscriber(destination, onNext, onComplete, onError, onFinalize);
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export default createOperatorSubscriber;

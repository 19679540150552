import type { ReactNode } from 'react';

const uri = {
  absolute(address: string): boolean {
    return /^(https?|file|ftps?|mailto|javascript|data:image\/[^;]{2,9};):/i.test(address);
  },
  relative(address: string): boolean {
    return !uri.absolute(address);
  },
  external(address: string): boolean {
    const uriDomain = (target: string): string | undefined => /((?:[\w-]+\.)+\w{2,})/i.exec(target)?.[0];
    const targetDomain = uriDomain(address);

    return (this.absolute(address) || Boolean(targetDomain)) && uriDomain(window.location.href) !== uriDomain(address);
  },
  internal(address: string): boolean {
    return !uri.external(address);
  },
  ip(address: string): boolean {
    const regexpIPAddress =
      // eslint-disable-next-line max-len
      /^(https*:\/{2})*(25[0-5]|2[0-4]\d|[01]?\d{1,2})\.(25[0-5]|2[0-4]\d|[01]?\d{1,2})\.(25[0-5]|2[0-4]\d|[01]?\d{1,2})\.(25[0-5]|2[0-4]\d|[01]?\d{1,2})$/;

    return regexpIPAddress.test(address);
  },
  linkfy(text: string, onMatch: (url: string) => ReactNode): Array<ReactNode> {
    const uriRegExp = /((?:https?:\/\/)*(?:www\.)?[\w#%+.:=@~-]{1,256}\.[\d()a-z]{1,6}\b[\w#%&()+./:=?@~-]*)/gim;

    return text
      .split(uriRegExp)
      .filter(Boolean)
      .map((value) => {
        if (uriRegExp.test(value)) {
          return onMatch(value);
        }

        return value;
      });
  },
};

export default uri;

import invariant from 'invariant';

import happen from './happen';

type DateInput = string;

interface DateInstance {
  isValid(): boolean;
  getAge(): number;
  toLuxMidnight(): Date;
  toLuxAt(time: string): Date;
  toISODateString(): DateInput;
}

const getLastSunday = (year: number, month: number): Date => {
  const parsedDate = new Date(Date.UTC(year, month, 0));

  parsedDate.setDate(parsedDate.getDate() - parsedDate.getDay());

  return parsedDate;
};

/**
 * @param {String} value - format "yyyy-mm-dd" or "yyyy-mm-ddT00:00:00.000Z"
 */
const date = (value: DateInput): DateInstance => {
  const normalizedDate = value?.split?.('T')?.[0] ?? '';
  const sourceDate = (function MountDate() {
    const [year, month, day] = normalizedDate.split('-').map(Number);
    const source = new Date();

    source.setFullYear(year, month - 1, day);

    return source;
  })();

  return {
    isValid() {
      const [year, month, day] = normalizedDate.split('-').map(Number);

      if (Number.isNaN(sourceDate.getTime()) || !year) return false;

      return sourceDate.getFullYear() === year && sourceDate.getMonth() + 1 === month && sourceDate.getDate() === day;
    },
    getAge() {
      invariant(
        date(value).isValid(),
        `[DATE]: getAge() method expects a valid date in the format "yyyy-mm-dd" or "yyyy-mm-ddT00:00:00.000Z",` +
          ` instead got "${value}".`
      );

      const today = new Date();
      let age = today.getFullYear() - sourceDate.getFullYear();
      const monthDiff = today.getMonth() - sourceDate.getMonth();

      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < sourceDate.getDate())) {
        age -= 1;
      }

      return age;
    },
    toLuxMidnight(): Date {
      invariant(
        date(value).isValid(),
        `[DATE]: toLuxMidnight() method expects a valid date in the format "yyyy-mm-dd" or` +
          ` "yyyy-mm-ddT00:00:00.000Z", instead got "${value}".`
      );

      const parsedDate = new Date(`${value}T00:00:00.000+01:00`);

      // DST starts at the last Sunday of March
      const dstStart = getLastSunday(parsedDate.getFullYear(), 3);

      // DST ends at the last Sunday of October
      const dstEnd = getLastSunday(parsedDate.getFullYear(), 10);

      const onDayLightSaving = happen(parsedDate).greaterThanOrEqual(dstStart) && happen(parsedDate).lessThan(dstEnd);

      if (onDayLightSaving) {
        return new Date(`${normalizedDate}T00:00:00.000+02:00`);
      }

      return parsedDate;
    },
    toLuxAt(time: string): Date {
      invariant(
        date(value).isValid(),
        `[DATE]: toLuxMidnight() method expects a valid date in the format "yyyy-mm-dd" or` +
          ` "yyyy-mm-ddT00:00:00.000Z", instead got "${value}".`
      );

      const timeRegex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;

      invariant(
        timeRegex.test(time),
        `[DATE] toLuxAt(time:string) method expects a valid time in the format "HH:MM:SS", instead got "${time}".`
      );

      const parsedTime = new Date(`${normalizedDate}T${time}.000+01:00`);

      // DST starts at the last Sunday of March
      const dstStart = getLastSunday(parsedTime.getFullYear(), 3);

      // DST ends at the last Sunday of October
      const dstEnd = getLastSunday(parsedTime.getFullYear(), 10);

      const onDayLightSaving = happen(parsedTime).greaterThanOrEqual(dstStart) && happen(parsedTime).lessThan(dstEnd);

      if (onDayLightSaving) {
        return new Date(`${normalizedDate}T${time}.000+02:00`);
      }

      return parsedTime;
    },

    /**
     * @returns {String} - format "yyyy-mm-dd"
     */

    toISODateString(): DateInput {
      invariant(
        date(value).isValid(),
        `[DATE]: toISODateString() method expects a valid date in the format "yyyy-mm-dd",` +
          `or "yyyy-mm-ddT00:00:00.000Z", instead got "${value}".`
      );

      return normalizedDate;
    },
  };
};

export type { DateInstance, DateInput };
export default date;

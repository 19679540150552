import is from './is';

interface ElementActions {
  scrollTo: (options: ScrollToOptions) => void;
  scrollTop: (top: number) => void;
  scrollLeft: (left: number) => void;
}

const element = (node: HTMLElement | typeof window | string): ElementActions => {
  const htmlNode = is.string(node) ? document.getElementById(node) : node;

  return {
    scrollTo(options: ScrollToOptions) {
      if (!is.func(htmlNode?.scrollTo)) return;

      htmlNode?.scrollTo({ behavior: 'smooth', ...options });
    },
    scrollTop(top: number) {
      this.scrollTo({ top });
    },
    scrollLeft(left: number) {
      this.scrollTo({ left });
    },
  };
};

export default element;

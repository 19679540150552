import { iif, of, switchMap } from 'rxjs';
import type { Observable } from 'rxjs';
import type { RouteAuthorization } from 'contracts';

const evalAuthorization = <T>(
  loading: (data: T) => boolean,
  authorization: (data: T) => Observable<boolean>
): ((source: Observable<T>) => Extract<RouteAuthorization['authorization$'], Observable<unknown>>) => {
  return (source$) => source$.pipe(switchMap((data) => iif(() => loading(data), of(undefined), authorization(data))));
};

export default evalAuthorization;
